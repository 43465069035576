@tailwind base;
@tailwind components;
@tailwind utilities;


@layer base {
  html, body {
    @apply transition-colors;
  }

  html {
    @apply font-sans leading-normal tracking-[-0.035px] text-zinc-800 md:text-lg md:leading-[1.6];
    @apply antialiased;
    @apply scroll-p-12;
    @apply leading-normal;
    -ms-text-size-adjust: 100%;
    -webkit-text-size-adjust: 100%;
  }

}

@layer components {
  #root {
    position: relative;
  }

  .link-underline {
    @apply border-b-2 border-dotted border-black/40 text-inherit;
  }

  .home {
    @apply m-auto max-w-screen-2xl min-h-screen text-black dark:text-white border-slate-500/0 3xl:border-slate-500/20 dark:border-neutral-300/0 dark:3xl:border-neutral-300/20 transition border-l duration-300;
  }

  .home a[href] {
    @apply transition-opacity hover:opacity-70;
  }

  .home button {
    @apply transition-opacity hover:opacity-70;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  .home p a {
    @apply link-underline border-black/40 dark:border-white/40;
  }
}
